export const IsMobileDevice = () => {
    //return if device is mobile
    return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
    );
};

// export const formatDate = (string) => {
//   if (string.length < 11) {
//     const fragment = string.split("") || [];
//     const formated = fragment.reduce((acum, item, i) => {
//       if ((i === 2 || i === 5) && item !== "/") acum.push("/");
//       acum.push(item);
//       return acum;
//     }, []);
//     return formated.join("");
//   }
//   return "";
// };

export const formatDate = (value) => {
  const cleanValue = value.replace(/\D/g, ""); // Remove non-digit characters
  let formattedDate = "";

  if (cleanValue.length <= 2) {
    formattedDate = cleanValue;
  } else if (cleanValue.length <= 4) {
    formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`;
  } else {
    formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}/${cleanValue.slice(4, 8)}`;
  }

  return formattedDate;
};


export const validateDOB = (dateString) => {
  let regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;

  //Check whether valid dd/MM/yyyy Date Format.
  if (regex.test(dateString)) {
    var parts = dateString.split("/");
    var dtDOB = new Date(parts[1] + "/" + parts[0] + "/" + parts[2]);
    console.log("dtDOB", dtDOB);
    // var dtCurrent = new Date();
    return true;
  }
  console.log("enter in dd/mm/year format only")
  return false;
};
